.card {
  overflow: hidden;
  position: relative !important;
}

.card img {
  aspect-ratio: 2/3;
  -o-object-fit: cover;
  object-fit: cover;
}

.card .overlay {
  cursor: pointer;
  font-size: min(1.7ch, 1.9vw);
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  isolation: isolate;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.card:hover .overlay {
  opacity: 1;
}

.card .overlay::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.322);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}