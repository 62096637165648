:root {
  scroll-behavior: smooth;
}

body {
  font-family: 'Almarai',sans-serif;
}

*,*::before,*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bg-custom-brown {
  background-color: chocolate;
}

.nav-logo {
  width: 70px;
}

input[type="search"] {
  border-radius: 50px;
  padding-inline: 1.5rem;
  caret-color: chocolate;
}

input[type="search"]:focus {
  box-shadow: none ;
  outline:5px solid rgba(var(--bs-danger-rgb));
}